import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Club" description="Wine and Cider Club by Archibald James" mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "club"
      }}>{`Club`}</h1>
      <h2 {...{
        "id": "the-latest-innovations-from-archibald-james-delivered-to-your-door"
      }}>{`The latest innovations from Archibald James, delivered to your door`}</h2>
      <p>{`Members enjoy an evolving variety of styles within our portfolio. We release products throughout the year, so there's always something new to try.`}</p>
      <p>{`We'll send you a quarterly assortment from our current lineup.`}</p>
      <h2 {...{
        "id": "experience-more-of-the-archibald-james-portfolio-choose-from-reds-whites-and-ciders"
      }}>{`Experience more of the Archibald James portfolio: Choose from reds, whites, and ciders`}</h2>
      <p>{`Our shipments are modular. Put together your package with one, two, or all three categories:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Red`}</strong>{`: three reds, every three months.`}</li>
        <li parentName="ul"><strong parentName="li">{`White`}</strong>{`: three whites, every three months.`}</li>
        <li parentName="ul"><strong parentName="li">{`Cider`}</strong>{`: six ciders, every three months.`}</li>
      </ul>
      <h2 {...{
        "id": "combine-categories-for-bigger-discounts"
      }}>{`Combine categories for bigger discounts`}</h2>
      <p>{`It's more economical to ship a few extra bottles. Plus, we really want you to branch out beyond your usual categories, so we'll offer you an extra discount on your orders.`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Single module membership: 15% off`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Any two: 20% off`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`All three: 25% off`}</p>
        </li>
      </ol>
      <h2 {...{
        "id": "enjoy-throughout-the-year"
      }}>{`Enjoy throughout the year`}</h2>
      <p>{`Dispatched to your door in March, June, September, and December. Or collect them yourself in Leavenworth.`}</p>
      <p>{`Use your discount to order more of your favorites on-line and in-store.`}</p>
      <h2 {...{
        "id": "-sign-up-now"
      }}>{`👍 `}<a parentName="h2" {...{
          "href": "https://club.archibaldjames.com"
        }}>{`Sign up now`}</a></h2>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      